import { useState } from "react";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import useI18n from "./useI18n";
import { jwtDecode } from "jwt-decode";
import { postData } from "../lib/fetchApi";
import publicService from "../api/public"
import { useRecoilState } from "recoil";
import { loginState } from '../state/login';
import { authModalState } from '../state/authModalState';
import { varificationPage, priceListPage, loginPage, defaultPage } from "../constants/pages";


export default function useLogin() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [user, setUser] = useState({});
    const [loginData, loginDataState] = useRecoilState(loginState);
    const [authModalOpenData, authModalOpenDataState] = useRecoilState(authModalState);

    const { language, l } = useI18n();

    const login = async ({ login, password, compno } = {}) => {
        console.log('log from useLogin :>> ', login, password, compno);
        setLoading(true);
        try {
            const result = await publicService.loginUserAsync({ email: login, password, compno }, language)
            if (result?.code) console.log('authentication code :>> ', result.code);
            loginDataState({email: login, timer: result?.timer || 5});

            if(!result?.token){
                navigate(varificationPage);
                return;
            }

            const decoded = jwtDecode(result.token);
            sessionStorage.setItem("jwt", result.token);
            setUser({
                decoded,
                token: result.token,
            });
            sessionStorage.setItem('company', decoded.company_id)
            sessionStorage.setItem('user', decoded.EMAIL)
            navigate(priceListPage);
        } catch (e) {
            setError(e.message || l("Authentication error"));
        } finally {
            setLoading(false);
        }
    };

    const authenticationEmail = async ({ email, code } = {}) => {
        try {
            const result = await publicService.authenticationEmail({ email, code }, language);
            const decoded = jwtDecode(result.token);
            sessionStorage.setItem("jwt", result.token);
            setUser({
                decoded,
                token: result.token,
            });

            sessionStorage.setItem('user', decoded.EMAIL)
            navigate(defaultPage);

        } catch (e) {
            setError(e.message || l("Authentication error"));
        } finally {
            setLoading(false);
        }
    };

    const logout = () => {
        sessionStorage.setItem("jwt", '');
        sessionStorage.setItem('user', "");
        sessionStorage.setItem('company', "");
        navigate(loginPage);
    };

    const expiredTokenLogout = () => {
        const userJwt = sessionStorage.getItem("jwt");
        if (userJwt && validator.isJWT(userJwt)) {
            const decoded = jwtDecode(userJwt);
            const currentTime = Date.now() / 1000;
            if (decoded.exp < currentTime) {
                return logout();
            }
        }
    };

    return {
        loading,
        error,
        user,
        setError,
        login,
        logout,
        expiredTokenLogout,
        authenticationEmail
    };
}
