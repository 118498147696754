import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';

export default function ConfirmDialog({ open = false, handleClose, handleConfirm, title, contentText, confirmButtonText }) {

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title"
                textAlign={'center'}
                >
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {contentText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Box display='flex' width='100%' justifyContent='center'>
                        <Button
                            variant="contained"
                            size='medium'
                            fullWidth
                            sx={{
                                maxWidth: '25%',
                                backgroundColor: '#EFB21C',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#F0B21C',
                                    color: 'white'
                                }
                            }}
                            onClick={handleConfirm}>
                            {confirmButtonText}
                        </Button>
                    </Box>  
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}