import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { LanguageProvider } from './i18n/LanguageContext';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
	components: {
	  MuiTextField: {
		styleOverrides: {
		  root: {
			'& label.Mui-focused': {
			  color: '#F0B21C',
			},
			'& .MuiInput-underline:after': {
			  borderBottomColor: '#F0B21C',
			},
			'& .MuiOutlinedInput-root': {
			//   '& fieldset': {
			// 	borderColor: '#F0B21C',
			//   },
			  '&:hover fieldset': {
				borderColor: '#F0B21C',
			  },
			  '&.Mui-focused fieldset': {
				borderColor: '#F0B21C',
			  },
			},
		  },
		},
	  },
	  MuiAutocomplete: {
		styleOverrides: {
		  root: {
			'& label.Mui-focused': {
			  color: '#F0B21C',
			},
			'& .MuiInput-underline:after': {
			  borderBottomColor: '#F0B21C',
			},
			'& .MuiOutlinedInput-root': {
			//   '& fieldset': {
			// 	borderColor: '#F0B21C',
			//   },
			  '&:hover fieldset': {
				borderColor: '#F0B21C',
			  },
			  '&.Mui-focused fieldset': {
				borderColor: '#F0B21C',
			  },
			},
		  },
		},
	  }
	},
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter basename="">
		<RecoilRoot>
			<LanguageProvider>
				<ThemeProvider theme={theme}>
					<App />
				</ThemeProvider>
			</LanguageProvider>
		</RecoilRoot>
	</BrowserRouter>
);

/*

	   </LanguageProvider>
*/