import { Box, Button, Divider, InputLabel, TextField, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import React from 'react';
import useI18n from '../../../hooks/useI18n';
import useUserInfo from '../../../hooks/useUserInfo';
import BasicModal from '../../custom/Modal/BasicModal';
import PhoneInput from '../../custom/PhoneInput/PhoneInput';

function UserSetting() {
    const { l, language } = useI18n();
    const { user, getCurrentUser } = useUserInfo();
    const [open, setOpen] = React.useState(false);
    const [userSetting, setUserSetting] = React.useState();

    React.useEffect(() => {
        const getData = async () => {
            const currentUser = getCurrentUser();
            //const result = await userService.findById(currentUser.id, language);
            setUserSetting(currentUser);
        }
        getData();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        //     if(userSetting?.phone === '') {
        //         setOpen(true);
        //         return;
        //     }

        //     if (user?.id) await userService.updateUserAsync({ id: user.id, data: userSetting, lang: language });
    }

    const handleOnChange = (event) => {
        //     let { name, checked, value } = event.target;

        //     if(name === 'phoneNumber'){
        //         setUserSetting({
        //             ...userSetting,
        //             'phone': value
        //         })
        //         return;
        //     }

        //     setUserSetting({
        //       ...userSetting,
        //       [name]: checked
        //     })
    }

    return (userSetting ?
        <Box
            component={'form'}
            onSubmit={handleSubmit}
            margin={'0 auto'}
            padding={2}
            borderRadius={2}
            sx={{ backgroundColor: 'white' }}>
            {open ?
                <BasicModal isOpened={true}
                    type="warning"
                    title={l('Warning')}
                    text={l('Phone number is required') + '!'}
                    handle={() => setOpen(false)}
                /> : ""
            }
            <Typography component="h2" variant="h5">{l('User setting')}</Typography>
            <Divider />
            <Box sx={{ margin: '10px 0', width: '50%' }}>
                <InputLabel htmlFor="email">{l('Email')}</InputLabel>
                <TextField name="email"
                    id="email"
                    defaultValue={userSetting?.EMAIL}
                    variant="outlined"
                    size="small"
                    autoComplete='off'
                    fullWidth
                    disabled='true'
                />
                <PhoneInput value={userSetting?.PHONE} name="phoneNumber" handleChange={handleOnChange} disabled='true' />
            </Box>

            {/*<Box display={'flex'} flexWrap={'wrap'} gap={1}>
                <FormGroup>
                    <FormControlLabel checked={userSetting?.alarm_to_mail} onChange={handleOnChange} name='alarm_to_mail' control={<Switch />} label={l("Alarm to mail")} />
                    <FormControlLabel checked={userSetting?.alarm_to_sms} onChange={handleOnChange} name='alarm_to_sms' control={<Switch />} label={l("Alarm to SMS")} />
                </FormGroup>
            </Box>*/}
            <Button sx={{ mt: 2, display: 'flex', flexGrow: 1 }} style={{ backgroundColor: '#F0B21C' }} type="submit" size="small" variant="contained">{l('Save')}</Button>
        </Box> : '');
}

export default UserSetting;