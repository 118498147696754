import React from 'react';
import { Autocomplete, Box, Button, Divider, TextField, Typography } from '@mui/material';
import TableData from '../../custom/Table/TableData';
import CellType from '../../../constants/type';
import useI18n from '../../../hooks/useI18n';
import Search from '../../custom/Search/Search';
import useUserInfo from '../../../hooks/useUserInfo';

import { ordersState } from '../../../state/ordersState';
import { updateBasketState } from '../../../state/updateBasketState';
import { useRecoilState } from "recoil";
import basketService from "../../../api/basket";
import orderService from "../../../api/order";

import { useParams } from 'react-router-dom';
import ConfirmDialog from '../../custom/ConfirmDialog/ConfirmDialog';

import { basketState } from '../../../state/basketState';

function Order() {

    const { lang, l } = useI18n();
    const { number } = useParams();

    const [orders, setOrders] = useRecoilState(ordersState);
    const [orderData, setOrderData] = React.useState([]);
    const [search, setSearch] = React.useState([]);

    const [searchValue, setSearchValue] = React.useState('');

    const { user } = useUserInfo();

    const [updateBasket, setUpdateBasket] = useRecoilState(updateBasketState);
    const [basketRecoil, setBasketRecoil] = useRecoilState(basketState);

    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);

    const [finaleTableData, setFinaleTableData] = React.useState([]);

    const getOrdersList = async () => {

        const data = orders?.find((item) => item.OFFICIALSERNR === number);
        
        if (data) {
            setOrderData(data)

            return;
        }

        try {
            const respons = await orderService.findAllByUserId(user.CODE, lang);
            if (orders) {
                setOrders(respons);
            }

            const dataRes = respons?.find((item) => item.OFFICIALSERNR === number);
            setOrderData(dataRes)

        } catch (error) {
            console.error('Error getOrdersList :>> ', error);
        }
    }


    React.useEffect(() => {
        if (user) {

            getOrdersList();
        }
    }, [user]);

    React.useEffect(() => {
        setFinaleTableData(setTableRows());
    }, [ orderData, search ]);

    const columns = [
        { id: 'ARTCODE', label: l('Code'), minWidth: 150, type: CellType.STRING },
        { id: 'FNAME', label: l('Name'), minWidth: 200, type: CellType.PRODUCT_NAME },
        { id: 'FIMG', label: l('Image'), minWidth: 80, type: CellType.IMG, align: 'center' },
        { id: 'CALCPRICE', label: l('Price'), minWidth: 100, type: CellType.NUMBER },
        { id: 'QUANT', label: l('Quantity'), minWidth: 80, type: CellType.NUMBER },
        { id: 'FINSTOCK', label: l('Availability'), minWidth: 120, type: CellType.FINSTOCK },
        { id: 'rdbSUM', label: l('Sum'), minWidth: 80, type: CellType.PRODUCT_SUM },
    ];

    const handelSearch = (value) => {

        if (value === '') {
            setSearchValue('');
            setSearch([]);
            return;
        }

        setSearchValue(value);

        setSearch(orderData?.math.filter((item) =>
            item.FNAME?.toLowerCase().includes(value.toLowerCase())
            || item.ARTCODE?.toLowerCase().includes(value.toLowerCase())
            || item.FNAMERUS?.toLowerCase().includes(value.toLowerCase())
            || item.SPEC?.toLowerCase().includes(value.toLowerCase())
        ));
    }

    function setTableRows() {

        if (search.length > 0) {
            return search;
        }
      
        return orderData?.math;
    }

    const copyOrderToBasket = async (isClear = false) => {
        try {
            await basketService.copyOrderToBasket({ orderNumber: orderData?.SERNR, isClear})
            setUpdateBasket({BoolUpdate: !updateBasket.BoolUpdate});
        } catch (error) {
            console.error('Error copyOrderToBasket :>> ', error);
        }

        try {
            const basket = await basketService.findAllByUserId(user.CODE, lang);
            setBasketRecoil({ basket });
        } catch (error) {
            console.error('Error handleAddToBasket :>> ', error);
        }
    }


    const handleAddToBasket = () => {
        copyOrderToBasket();
    }

    const handleRewriteBasket = () => {
        copyOrderToBasket(true);
        setOpenConfirmDialog(false)
    }

    return (
        <>
            <Box display="flex" justifyContent="space-between" margin={'10px 0'} >
                <Box display="flex" flexGrow={1} justifyContent='space-between' >
                    <Box>
                        <Typography component="h2" variant="h5">
                            {l('Order')}: {number}
                        </Typography>
                    </Box>
                    <Box display='flex' gap={2}>
                        <Button
                            variant="contained"
                            autoFocus
                            color="success"
                            onClick={handleAddToBasket}
                        >
                            {l('Add to basket')}
                        </Button>
                        <Button
                            variant="contained"
                            color="warning"
                            onClick={() => setOpenConfirmDialog(true)}
                        >
                            {l('Rewrite basket')}
                        </Button>
                    </Box>
                    <Box>
                        <Typography display='flex' component="p" variant="p">
                            {l('Order amount')}: {orderData?.SUM4}$
                        </Typography>
                        <Box display="flex">
                            <Typography color="#dc3545" marginRight={1} component="p" variant="p">
                                {orderData.totweight} {l('Kg')}
                            </Typography>
                            <Typography color="#007bff" component="p" variant="p">
                                {orderData?.totvolume} {l('m³')}
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                <Search onChange={handelSearch} value={searchValue} />
            </Box>
            <Box display="flex">
                <Box width="100%">
                    <TableData
                        columns={columns}
                        rows={setTableRows()}
                    />
                </Box>
            </Box>
            <ConfirmDialog 
            handleConfirm={handleRewriteBasket} 
            open={openConfirmDialog} 
            handleClose={() => setOpenConfirmDialog(false)} 
            title={`⚠️ ${l('Rewrite basket')}`}
            contentText={`${l('Are you sure that you want to rewrite the basket, all other products that were selected will be deleted')}!`}
            denyButtonText={l('Cancel')}
            confirmButtonText={l('Agree')}
            />
        </>
    );
}

export default Order;