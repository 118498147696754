const CellType = {
    NUMBER:  'NUMBER',
    DATE:'DATE',
    DATEONLY: 'DATEONLY',
    BOOLEAN: 'BOOLEAN',
    STRING: 'STRING',
    BOOLCONNECTED: 'BOOLCONNECTED',
    BOOLSTATUS: 'BOOLSTATUS',
    BOOLALARM: 'BOOLALARM',
    EDITABLE_NUMBER: 'EDITABLE_NUMBER',
    ACTIONS: 'ACTIONS',
    IMG: 'IMG',
    PRODUCT_NAME: 'NAME_PRODUCT',
    PRODUCT_SUM: 'SUM_PRODUCT',
    PRISE_WITH_DISCOUNT: 'PRISE_WITH_DISCOUNT',
    FINSTOCK: 'FINSTOCK',
}

export default CellType;
