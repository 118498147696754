import * as React from 'react';
import { IconButton, Modal } from '@mui/material';
import Box from '@mui/material/Box';

export default function DetailScreen({ isOpened = false, setOpenDetail, detailUrl }) {

  const handleClose = () => setOpenDetail(false);

  const handleRedirecting = () => {
    setOpenDetail(false);
    window.open(detailUrl, '_blank');
  };

  return (
    <>
      <Modal open={isOpened} onClose={handleClose}>
        <Box onClick={handleClose} height='100%' margin='20px 0 0 120px' display='flex' justifyContent='center'>
          <Box display='flex' width='80%' height='calc(100% - 50px)'>
            <iframe
              style={{ background: 'white', border: '5px solid #f9a800', borderRadius: '5px' }}
              name="myframe"
              width="100%"
              height="100%"
              title="Detailed Content"
              src={detailUrl} ></iframe>

            <Box className="detail-close-btn" display='flex'>
              <IconButton onClick={handleRedirecting}>
                <svg
                  style={{ cursor: 'pointer', color: 'white' }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="26px"
                  height="26px"
                  fill="currentColor"
                  className="bi bi-box-arrow-up-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"
                  />
                  <path
                    fillRule="evenodd"
                    d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"
                  />
                </svg>
              </IconButton>

              <IconButton onClick={handleClose}>
                <svg
                  style={{ cursor: 'pointer', color: 'white' }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="30px"
                  height="30px"
                  fill="currentColor"
                  className="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                </svg>
              </IconButton>

            </Box>
          </Box>
        </Box>
      </Modal>

    </>
  );
}