import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import { defaultLocale } from "../config";

const isValidJSONString = (jsonString) => {
    try {
        JSON.parse(jsonString);
    } catch (e) {
        return false;
    }
    return true;
};

export const getImage = async ({ url, lang }) => {
    let headers = { "Content-Language": lang };
    const token = sessionStorage.getItem('jwt');
    if (token) {
        headers = {
            ...headers,
            Authorization: `Bearer ${token}`,
        };
    }
    const response = await fetch(url, {
        method: 'GET',
        headers
    });
        
    // Check if response is successful
    if (!response.ok) {
        throw new Error(`Failed to fetch image: ${response.statusText}`);
    }
    
    // Get the image blob from the response
    const blob = await response.blob();
    return blob;
}
export const getData = async ({ url, lang = defaultLocale }) => {

    const token = sessionStorage.getItem('jwt');

    let headers = {
        "Content-Type": "application/json",
        "Content-Language": lang,
    };
    if (token) {
        headers = {
            ...headers,
            Authorization: `Bearer ${token}`,
        };
    }

    // console.log('url :>> ', url);
    const response = await fetch(url, {
        method: "GET",
        headers,
    });
    // console.log('response :>> ', response);
    if (!response.ok) {
        if (response.statusText) throw new Error(response.statusText);
        throw new Error('Forbidden');
    }

    return await response.json();
};


export const postData = async ({
    url,
    data = {},
    file = null,
    lang = defaultLocale,
    noParsedResult = false,
    type
}) => {
    const token = sessionStorage.getItem('jwt');
    let headers = new Headers();
    headers = { ...headers,  "Content-Language": lang };
    let body = null;

    if (type === 'form-data') {
        const formData = new FormData();
        formData.append('data', JSON.stringify(data));
        formData.append('file', file)
        body = formData;
    } else {
        headers = {
            ...headers,
            "Content-Type": 'application/json'
        };
        body = JSON.stringify(data);
    }
   
    if (token) {
        headers = {
            ...headers,
            Authorization: `Bearer ${token}`,
        };
    }

    const response = await fetch(url, { method: "POST", body, headers });

    const resData = await response.text();
    if (noParsedResult) return resData;

    // якщо в запиті немає помилки, відповідь приходить як json, а коли є - як text
    if (response.ok) {
        // може прийтий пустий контент
        if (!resData) return null;

        if (resData && isValidJSONString(resData)) {
            return JSON.parse(resData);
        } else {
            return resData || null;
        }
    }

    if(resData && isValidJSONString(resData)){
        const errorData = JSON.parse(resData);
        const keyNames = Object.keys(errorData);
        throw new Error(errorData[keyNames[0]]);
    }

    throw new Error(resData);
}