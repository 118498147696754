import { useEffect } from "react";
import { useState } from "react";
import { jwtDecode } from "jwt-decode";


function useUserInfo() {
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        getCurrentUser();
    }, []);

    const getCurrentUser = () => {
        try {
            const jwt = sessionStorage.getItem("jwt");
            const decoded = jwtDecode(jwt);
            setUser({ ...decoded });
            return decoded;
        } catch (error) {
            console.error('Error fetching roles:', error);
            setError(error)
        }
    };

    return { user, error, getCurrentUser };
}

export default useUserInfo;