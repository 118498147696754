import { getData, postData } from "../lib/fetchApi";
import { PRICELISTS_URL } from "../constants/urls";
import { defaultLocale } from "../config";
import useI18n from "../../../client/src/hooks/useI18n";

const priceListService = {

    findAllByUserId: (data, lang) => {
        return postData({ url: PRICELISTS_URL.FIND_ALL_BY_USER_ID, data, lang });
    },
    getFiltersByPLCode: (lang) => {
        return getData({ url: PRICELISTS_URL.GET_FILTERS_BY_PL_CODE, lang });
    },
    getFiltersArrayByPLCode: (lang) => {
        return getData({ url: PRICELISTS_URL.GET_FILTERS_ARRAY_BY_PL_CODE, lang });
    },
    getOrderedByCategoryAndSumByCustCode: (lang) => {
        return getData({ url: PRICELISTS_URL.ORDERED_BY_CATEGORIES_BY_CUST_CODE, lang });
    }
}
export default priceListService;
