
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';
import useI18n from '../../../hooks/useI18n';
import userService from '../../../api/user';
import Alert from '@mui/material/Alert';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export default function PasswordChange() {
    const navigate = useNavigate();
    const { l, language } = useI18n();
    const [passwordsMatch, setPasswordsMatch] = React.useState(true);
    const [showOldPassword, setShowOldPassword] = React.useState(false);
    const [showNewPassword, setShowNewPassword] = React.useState(false);
    const [showNewPasswordRepeat, setShowNewPasswordRepeat] = React.useState(false);


    const [formErrors, setFormErrors] = React.useState({
        oldPassword: true,
        newPassword: true
    });

    const [alertState, setAlertState] = React.useState({
        show: false,
        severity: 'success', // Default to success alert
        message: '',
    });

    const handleTogglePassword = (pass) => {
        switch (pass) {
            case 0: {
                console.log(pass)
                setShowOldPassword(show => !show);
                break;
            }
            case 1: {
                console.log(pass)
                setShowNewPassword(show => !show);
                break;
            }
            case 2: {
                console.log(pass)
                setShowNewPasswordRepeat(show => !show);
                break;
            } 
            default: {
                break;
            }
        }
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const oldPassword = data.get('old_password');
        const newPassword = data.get('new_password');
        const repeatNewPassword = data.get('repeat_new_password');
        const newFormErrors = {
            oldPassword: !!oldPassword,
            newPassword: !!newPassword
        };
        setFormErrors(newFormErrors);

        if (newPassword !== repeatNewPassword) {
            console.log('Passwords do not match');
            setPasswordsMatch(false);
            return;
        }
        setPasswordsMatch(true);
        const newPasswordData = { oldPassword, newPassword }
        try {
            await userService.changePasswordAsync(newPasswordData, language);
            setAlertState({ show: true, severity: 'success', message: 'Password changed successfully!' });
        } catch (error) {
            console.error('Error fetching change password:', error);
            setAlertState({ show: true, severity: 'error', message: error.message });
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    padding: 5,
                    borderRadius: 1,
                }}
            >
                <Avatar style={{ backgroundColor: '#F0B21C'}} sx={{ m: 1 }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {l('Password Change')}
                </Typography>
                {alertState.show && (
                    <Alert severity={alertState.severity}>
                        {alertState.message}
                    </Alert>
                )}
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        size='small'
                        id="old_password"
                        type={showOldPassword ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton onClick={() => handleTogglePassword(0)} onMouseDown={handleMouseDownPassword} edge="end">
                                        {showOldPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        label={l('Old Password')}
                        name="old_password"
                        autoFocus
                        error={!formErrors.oldPassword}
                        helperText={!formErrors.oldPassword && l('Old password is required')}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        size='small'
                        name="new_password"
                        label={l('New Password')}
                        type={showNewPassword ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton onClick={() => handleTogglePassword(1)} onMouseDown={handleMouseDownPassword} edge="end">
                                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        id="new_password"
                        error={!formErrors.newPassword}
                        helperText={!formErrors.newPassword && l('New password is required')}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        size='small'
                        name="repeat_new_password"
                        label={l('Repeat New Password')}
                        type={showNewPasswordRepeat ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton onClick={() => handleTogglePassword(2)} onMouseDown={handleMouseDownPassword} edge="end">
                                        {showNewPasswordRepeat ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        id="repeat_new_password"
                        error={!passwordsMatch}
                        helperText={!passwordsMatch && l('Passwords do not match')}
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        size='large'
                        style={{ backgroundColor: '#F0B21C'}}
                        sx={{ mt: 2, mb: 2 }}
                    >
                        {l('Save')}
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}
