export const defaultPage = '/';
export const loginPage = '/login';
export const forgotPasswordPage = '/forgot-password';
export const authenticationEmailPage = '/authentication-email';
export const userPage = '/user';
export const ordersPage = '/orders';
export const priceListPage = '/pricelist';
export const basketPage = '/basket';
export const mainPage = '/main';
export const publicPage = '/public';
export const varificationPage = '/varification';
export const userSettingPage = '/user-setting';
export const passwordChangePage = '/password-change';
export const orderPage = '/order';


