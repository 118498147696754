import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import { Badge } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalMallSharpIcon from '@mui/icons-material/LocalMallSharp';
import PlaylistAddCheckCircleSharpIcon from '@mui/icons-material/PlaylistAddCheckCircleSharp';
import PersonIcon from '@mui/icons-material/Person';
import ErrorIcon from '@mui/icons-material/Error';
import SmsIcon from '@mui/icons-material/Sms';
import {userPage, ordersPage, priceListPage, basketPage, mainPage} from '../../../constants/pages';

import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore, Settings as SettingsIcon } from '@mui/icons-material';

import SlideBarButtonList from './SlideBarButtonList';
import useI18n from '../../../hooks/useI18n';

import useUserInfo from '../../../hooks/useUserInfo';

import { useRecoilState } from "recoil";
import { basketState } from '../../../state/basketState';
import { updateBasketState } from '../../../state/updateBasketState';
import BasketDialog from '../../pages/Basket/BasketDialog';

import basketService from '../../../api/basket';

const GKBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#F0B21C', // Your desired color
    color: 'white', // Optional: Text color
  },
}));

const drawerWidth = 230;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.standard,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.standard,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

let navPageActive = {};
navPageActive[priceListPage] = 'Price list';
navPageActive[ordersPage] = 'Orders';
navPageActive[basketPage] = 'Basket';

export default function SlideBarMenu(prop) {
  const { open, setOpen, settingsOpen, setSettingsOpen } = prop;
  const navigate = useNavigate();
  const location = useLocation();
  const { l, language } = useI18n();
  const [activePage, setActivePage] = React.useState(l(navPageActive[location.pathname]));
  const [openDialog, setOpenDialog] = React.useState(false);
  const {user} = useUserInfo();
  const [basketSize, setBasketSize] = React.useState(0);

  const [basketData, setBasketData] = useRecoilState(basketState);
  const [updateBasket, setUpdateBasket] = useRecoilState(updateBasketState);

  async function getBasketCount() {
    try {
      const res = await basketService.getCountOfProductInBasket();
      setBasketSize(res.count);
    } catch (error) {
      setBasketSize(0);
      console.error('Error getCountOfProductInBasket >> ', error
      );
    }
  }

  React.useEffect(() => {

    setActivePage(l(navPageActive[location.pathname]));
    getBasketCount();
      
  }, [basketData, updateBasket, location.pathname]);

  React.useEffect(() => {
    if (!open && settingsOpen) {
      setSettingsOpen(false);
    }
  }, [open, settingsOpen, setSettingsOpen]);

  const handleSettingsClick = () => {
    setSettingsOpen(!settingsOpen);
    if (!open) {
      setOpen(true); // Open the sidebar if it's closed
    }
  };


  const slideBarButtonsListTop = [
    {
      text: l('Price list'),
      onClick: () => { navigate(priceListPage); setActivePage(l(navPageActive[priceListPage]))},
      icon: <PlaylistAddCheckCircleSharpIcon />
    },
    {
      text: l('Orders'),
      onClick: () => { navigate(ordersPage); setActivePage(l(navPageActive[ordersPage]))},
      icon: <LocalMallSharpIcon />
    },
    {
      text: l('Basket'),
      onClick: () => { navigate(basketPage); setActivePage(l(navPageActive[basketPage]))},
      icon: (
        basketSize ?
          <GKBadge badgeContent={basketSize} color="primary">
            <ShoppingCartIcon />
          </GKBadge>
          :
          <ShoppingCartIcon />
      )
    },
    // {
    //   text: l('Basket'),
    //   onClick: () => { setOpenDialog(!openDialog)},
    //   icon: (
    //     basketSize ?
    //       <GKBadge badgeContent={basketSize} color="primary">
    //         <ShoppingCartIcon />
    //       </GKBadge>
    //       :
    //       <ShoppingCartIcon />
    //   )
    // }  
  ];

  const slideBarButtonsListBottom = [
    {
      text: l('Alarms'),
      onClick: () => { navigate('/alarms'); setActivePage(l('Alarms'))},
      icon: (
        '' ?
          <Badge badgeContent={''} color="#F0B21C">
            <ErrorIcon />
          </Badge>
          :
          <ErrorIcon />
      )
    },
  ];

  const settingsMenuItems = [
    {
      text: l(''),
      onClick: () => {
        navigate('/main');
        setActivePage(l(''))
      },
      icon: <SmsIcon />
    }
  ];

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader />
      <Divider />
      <SlideBarButtonList user={user} buttons={slideBarButtonsListTop} open={open} activePage={activePage} />
      {/* <BasketDialog open={openDialog} handleClickOpen={() => {setOpenDialog(true)}} handleClose={() => {setOpenDialog(false)}}/> */}
      {/* <Divider />
      <ListItem button onClick={handleSettingsClick}>
        <ListItemIcon><SettingsIcon /></ListItemIcon>
        <ListItemText primary={l('Settings')} />
        {settingsOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={settingsOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {settingsMenuItems.map((item, index) => (
            <ListItem button key={index} onClick={item.onClick}>
              <ListItemIcon className={item.text === activePage ? 'active-button' : ""}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Collapse>
      <Divider />
      <SlideBarButtonList buttons={slideBarButtonsListBottom} open={open} activePage={activePage} /> */}
    </Drawer>
  );
}