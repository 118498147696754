import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmDialog({ open = false, handleClose, handleConfirm, handleDeny, title, contentText, denyButtonText, confirmButtonText }) {

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {contentText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained"
                            color="warning" 
                            onClick={handleDeny || handleClose} 
                            autoFocus>
                        {denyButtonText}
                    </Button>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={handleConfirm}>
                        {confirmButtonText}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}