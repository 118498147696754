import React, { createContext, useState } from "react";
import langs from "./index";
import { defaultLocale } from "../config";
import { CURR_LOCALE } from "../enums/storageKeys";
import langFiles from "./lang";

const LanguageContext = createContext();

const defaultLanguge = langFiles.includes(localStorage.getItem('CurrentLocale'))  || defaultLocale;

const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState(defaultLanguge);

    const l = (text = "") => {
        return langs[language][text] || text;
    };

    const changeLanguage = (newLanguage) => {
        setLanguage(newLanguage);
        localStorage.setItem(CURR_LOCALE, newLanguage);
    };

    return (
        <LanguageContext.Provider value={{ language, changeLanguage, l }}>
            {children}
        </LanguageContext.Provider>
    );
};

export { LanguageContext, LanguageProvider };
